/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Promo from "./Promo";
import Nav from "./Nav";

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false);

    return (
        <MyContext.Provider value={{
            isMenuOpen: menuOpenState,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
        }}>
            {props.children}
        </MyContext.Provider>
    );
};

const Header = (props) => {
    // determined if page has scrolled and if the view is on mobile
    const [scrolled, setScrolled] = useState(false);
    // change state on scroll
    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleScroll = () => {
                const isScrolled = window.scrollY > 10;
                if (isScrolled !== scrolled) {
                    setScrolled(!scrolled);
                }
            };

            document.addEventListener("scroll", handleScroll, { passive: true });

            return () => {
                // clean up the event handler when the component unmounts
                document.removeEventListener("scroll", handleScroll, { passive: true });
            };
        }
    }, [scrolled]);

    return (
        <header data-active={scrolled} className={`${props?.stickyMenu ? "header sticky top-0" : ""} w-full`}>
            <MyProvider>
                <Promo options={props.promo} />
                <Nav key={"mynav"} categories={props?.categories} mainMenu={props?.menu}/>
            </MyProvider>
        </header>
    );
};

export default Header;
