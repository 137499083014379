/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";

const Promo = ({options}) => {

	return (
		options?.activePubPromo == "checked" ?
			<div className="promo-container"
				style={{ backgroundColor: options?.promoBackgroundColor }}>
				<div
					className="promo lg:container flex justify-center items-center mx-auto text-center text-white font-helvetica-text font-medium text-xs tracking-wider"
					style={{ backgroundColor: options?.promoBackgroundColor, color: options?.promoColor }}
				>
					{options?.promoText ? options?.promoText : ""}
				</div>
			</div >
			: <></>
	);
};

export default Promo;
