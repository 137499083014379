import React from "react";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Switch from "@material-ui/core/Switch";
import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = withStyles({
    root: {
        border: 0,
        border: "none",
        boxShadow: "none",

        "& .MuiPaper-root": {
            height: "0px",
        },

        "&:not(:last-child)": {
            border: 0,
        },
        "&.MuiAccordion-root:before": {
            backgroundColor: "white",
        },
        "&:before": {
            display: "none",
            border: 0,
            border: "none",
            height: "0px",
            content: "none",
            backgroundColor: "white",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        fontFamily: "Helvetica Now Text",
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        border: 0,

        "&:before": {
            display: "none",
            border: 0,
            height: 0,
            content: "none",
        },

        // minHeight: 56,
        "&$expanded": {
            // minHeight: 56,
            border: 0,
        },
    },
    content: {
        border: 0,
        "&$expanded": {
            // margin: '12px 0',
            border: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        // padding: theme.spacing(2),
        border: 0,
    },
}))(MuiAccordionDetails);

export function CustomizedAccordionsCookies(props) {
    const [expanded, setExpanded] = React.useState("");

    const [state, setState] = React.useState({
        panel0: false,
        panel1: false,
        panel2: false,
        panel3: false,
        panel4: false,
        panel5: false,
        panel6: false,
    });

    const handleChange2 = (event) => {
        event.stopPropagation();
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { sections } = props;

    return (
        <div>
            <Divider className="mt-2" variant="middle" />
            {sections.map((section, index) => {
                return (
                    <>
                        <Accordion
                            elevation={0}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                className="border-0"
                                aria-controls={`panel${index}d-content`}
                                id={`panel${index}d-header`}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <h3 className="w-full ml-1 lg:ml-5 font-medium leading-8 font-helvetica-text  text-base cursor-pointer hover:text-blue-prodbuttons text-gray-text">
                                    {section.title}
                                </h3>
                                {index == 0 ? (
                                    <p className="font-helvetica-text text-sm"> Siempre activado</p>
                                ) : (
                                        <Switch
                                            checked={state[`panel${index}`]}
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={handleChange2}
                                            onFocus={(event) => event.stopPropagation()}
                                            color="primary"
                                            name={`panel${index}`}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                        />
                                    )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="ml-6 lg:ml-10 text-sm font-helvetica-text">
                                    {section.content}
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Divider className="mt-2" variant="middle" />
                    </>
                );
            })}
        </div>
    );
}