/* eslint-disable react/react-in-jsx-scope */
import Link from "next/link";
import { useContext, useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { getFormattedCart, getUpdatedItems, getFormattedCartToWhatsapp } from "../../../functions";
import { event, itemsGoogleAnalytics } from "../../trakers/google";
import { browserName, browserVersion, osVersion, osName } from "react-device-detect";
import { AppContext } from "../../context/AppContext";
import { useMyContext } from "../../context/auth-context";
import FlyCartItem from "./FlyCartItem";
import EmptyCart from "./EmptyCart";
import UPDATE_CART from "../../../mutations/update-cart";
import GET_CART from "../../../queries/get-cart";
import DELETE_LINE_ITEMS_ORDER from "../../../queries/delete-line-items-order";
import CREATE_ORDER from "../../../mutations/create-order";
import UPDATE_ORDER_MUTATION from "../../../mutations/update-order";
import GET_CLIENTE_QUERY from "../../../queries/get-client";
import LoadingSpinnerCss from "../../LoadingSpinnerCss";

const GET_ORDERS_STATUS_BY_ID_QUERY = gql`query GET_ORDERS_STATUS_BY_ID_QUERY($protectedBy:String, $orderId:Int) {
	getStatusOrder(protectedBy: $protectedBy, orderId: $orderId) {
    	status
  }
}
`;

const FlyCartItemsContainer = () => {

	// @TODO wil use it in future variations of the project.
	const [cart, setCart] = useContext(AppContext);
	const [requestError, setRequestError] = useState(null);
	const [user, setUser] = useMyContext();
	const [dataUpdate, setDataUpdate] = useState([]);

	// Get Cart Data.
	const { loading: loadCart, error: errorCart, data: dataCart, refetch } = useQuery(GET_CART, {
		fetchPolicy: "no-cache",
		onCompleted: () => {
			// Update cart in the localStorage.
			const updatedCart = getFormattedCart(dataCart);
			//if ( typeof window !== "undefined" ) {
			localStorage.setItem("classic-cart", JSON.stringify(updatedCart));
			//}
			// Update cart data in React Context.
			setCart(updatedCart);
		},
		onError: (error) => {
			if (error) {
				const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : "";
				if (errorMessage == "Expired token") {
					var cart = localStorage.getItem("classic-cart");
					if (cart) localStorage.setItem("classic-cart", null);
					localStorage.removeItem("woo-session");
					setRequestError("La sesión habia caducado, inténtelo de nuevo");
				} else setRequestError(errorMessage);
			}
		}
	});

	// Update Cart Mutation.
	const [updateCart, { data: updateCartResponse, loading: updateCartProcessing, error: updateCartError }] = useMutation(UPDATE_CART, {
		onCompleted: () => {
			let tmpCart = JSON.parse(localStorage.getItem("classic-cart"));
			setCart(tmpCart);
			refetch();
		},
		onError: (error) => {
			if (error) {
				const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : "";
				if (errorMessage == "Expired token") {
					var cart = localStorage.getItem("classic-cart");
					if (cart) localStorage.setItem("classic-cart", null);
					localStorage.removeItem("woo-session");
					setRequestError("La sesión habia caducado, inténtelo de nuevo");
				} else setRequestError(errorMessage);
			}
		}
	});

	const [checkout, { data: checkoutResponse, loading: checkoutLoading, error: checkoutError }] = useMutation(CREATE_ORDER, {
		onCompleted: (data) => {
			localStorage.setItem("classic-order", JSON.stringify(data.createOrder.order));
			window.location = "/checkout/?step=information";
		},
		onError: (checkoutError) => {
			if (checkoutError) {
				setRequestError(checkoutError.graphQLErrors[0].message);
			}
		}
	});

	const [updateOrder, { data: updateOrderResponse, loading: updateOrderProcessing, error: updateOrderError }] = useMutation(UPDATE_ORDER_MUTATION, {
		fetchPolicy: "no-cache",
		onCompleted: () => {
			window.location = "/checkout/?step=information";
		},
		onError: (updateOrderError) => {
			if (updateOrderError) {
				const errorMessage = updateOrderError?.graphQLErrors?.[0]?.message ? updateOrderError.graphQLErrors[0].message : "";
			}
		}
	});

	const [getOrderStatus, { loading: loadOrderStatus, error: errorOrderStatus, data: dataOrderStatus }] = useLazyQuery(GET_ORDERS_STATUS_BY_ID_QUERY, {
		fetchPolicy: "network-only",
		onCompleted: () => {
			let orderLocal = JSON.parse(localStorage.getItem("classic-order"));
			if (orderLocal.status != dataOrderStatus?.getStatusOrder?.status) {
				localStorage.removeItem("classic-order");
			}

		},
		onError: (errorOrderStatus) => {
			//if (errorOrderStatus) {}
		}
	});

	const [deleteLineItemsOrder, { loading: loadDeleteLineItemsOrder, error: errorLineItemsOrder, data: dataLineItemsOrder }] = useLazyQuery(DELETE_LINE_ITEMS_ORDER, {
		fetchPolicy: "network-only",
		onCompleted: () => {
			updateOrder({
				variables: {
					input: dataUpdate
				}
			});
		},
		onError: (errorLineItemsOrder) => {
			/* if (errorLineItemsOrder) {
			 }*/
		}
	});

	const { loading: loadUser, error: errorUser, data: dataUser } = useQuery(GET_CLIENTE_QUERY, {
		variables: {
			protectedBy: "ClassicJeans21",
			username: user?.username
		},
		onCompleted: () => { }
	});


	//if (loadCart) return <LoadingData />;

	/*
	 * Handle remove product click.
	 *
	 * @param {Object} event event
	 * @param {Integer} Product Id.
	 *
	 * @return {void}
	 */
	const handleRemoveProductClick = (event, cartKey, products) => {

		event.stopPropagation();
		if (products.length) {

			// By passing the newQty to 0 in updateCart Mutation, it will remove the item.
			const newQty = 0;
			const updatedItems = getUpdatedItems(products, newQty, cartKey);

			updateCart({
				variables: {
					input: {
						clientMutationId: v4(),
						items: updatedItems
					}
				},
			});

			let tmpCart = JSON.parse(localStorage.getItem("classic-cart"));
			setCart(tmpCart);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			let orderLocal = JSON.parse(localStorage.getItem("classic-order"));
			if (orderLocal) {
				getOrderStatus({
					variables: {
						orderId: orderLocal.orderId,
						protectedBy: "ClassicJeans21"
					}
				});
			}
		}
	}, []);

	const handleWhatsappClick = () => {

		if (!isEmpty(cart)) {
			//enviar a google analytics
			let numero = cart?.totalProductsPrice?.replace("$", "");
			let value = parseFloat(numero.replace(".", "").replace(",", ""));
			let itemsG = itemsGoogleAnalytics(cart);

			event("send_to_whatsapp", {
				"value": value,
				"currency": "COP",
				"items": itemsG
			});
		}
		const formatWhatsapp = getFormattedCartToWhatsapp(cart);
		window.location = formatWhatsapp;
	};

	const myOrder = () => {

		var orderLocal = JSON.parse(localStorage.getItem("classic-order"));

		let checkoutData = {
			lineItems: []
		};

		cart.products.forEach(element => {
			let valueToPush = { productId: 0, quantity: 0, variationId: 0 };
			valueToPush["productId"] = element.productId;
			valueToPush["quantity"] = element.qty;
			valueToPush["variationId"] = element.variationId;
			checkoutData.lineItems.push(valueToPush);
		});

		if (orderLocal && orderLocal?.orderId && orderLocal?.status == "PENDING") {

			let oId = { orderId: orderLocal.orderId };
			let client = { clientMutationId: v4() };
			Object.assign(checkoutData, oId, client);

			setDataUpdate(checkoutData);

			deleteLineItemsOrder({
				variables:
				{
					orderId: orderLocal.orderId,
					protectedBy: "ClassicJeans21",
					type: "line_item",
				}
			});

		} else {
			if (!isEmpty(user) && !isEmpty(dataUser?.getUser)) {
				checkout({
					variables: {
						input: {
							clientMutationId: v4(),
							customerId: user?.userId,
							billing: {
								firstName: user?.firstName,
								lastName: user?.lastName,
								email: dataUser?.getUser?.email,
								phone: dataUser?.getUser?.celular,
							},
							lineItems: checkoutData.lineItems,
							metaData: [{
								key: "Dispositivo Usado",
								value: browserName + "-" + browserVersion + "-" + osName + "-" + osVersion,
							}],
						}
					}
				});
				//	isPaid: false, al final del input va esto
			} else {
				checkout({
					variables: {
						input: {
							clientMutationId: v4(),
							lineItems: checkoutData.lineItems,
							metaData: [{
								key: "Dispositivo Usado",
								value: browserName + "-" + browserVersion + "-" + osName + "-" + osVersion,
							}],
						}
					}
				});
				//	isPaid: false, al final del input va esto
			}
		}
	};

	return (
		<div>
			{cart ? (
				<div className="classic-cart-wrapper flex flex-col justify-between">
					<div className={`${osName == "iOS" ? "scroll-container-ios" : "scroll-container"}`}>
						<div className="cart-products-drawer px-5">
							{cart?.products?.length && (
								cart.products.map((item) => (
									<div key={item.cartKey}>
										<FlyCartItem
											item={item}
											updateCartProcessing={updateCartProcessing}
											products={cart.products}
											handleRemoveProductClick={handleRemoveProductClick}
											updateCart={updateCart}
										/>
										<div className="mt-4"></div>
									</div>
								))
							)}
						</div>
					</div>
					{/*Cart Total*/}
					<div className="classic-cart-total-container w-screen sm:w-[504px] flex-col mx-auto p-4 justify-between h-1/3">
						<div className="flex justify-between">
							<div className="classic-cart-element-total text-base font-helvetica-text font-medium text-black">
								Subtotal
								{cart?.products?.length > 1 ?
									<span className="font-normal antialiased">{" (" + cart?.products?.length + " Productos)"}</span>
									:
									<span className="font-normal antialiased">{" (" + cart?.products?.length + " Producto)"}</span>
								}
							</div>
							<div className="classic-cart-element-amt text-base font-helvetica-text font-medium text-black antialiased">
								{("string" !== typeof cart?.totalProductsPrice) ? cart?.totalProductsPrice?.substring(0, cart?.totalProductsPrice?.length - 3) : cart?.totalProductsPrice}
							</div>
						</div>
						<button onClick={() => { if (!loadCart) myOrder(); }} className="bg-black hover:opacity-80 text-white px-5 py-3 rounded-md w-full mt-5">
							{loadCart ? <LoadingSpinnerCss /> : <span className="classic-cart-checkout-txt font-helvetica-text font-medium text-sm antialiased h-5">Paga por la Página</span>}
						</button>
						<button className="bg-green-whatsapp hover:opacity-80 text-white px-5 py-3 rounded-md w-full mt-3"
							onClick={() => { if (!loadCart) handleWhatsappClick(); }}>
							{loadCart ? <LoadingSpinnerCss /> : <span className="classic-cart-checkout-txt font-helvetica-text font-medium text-sm antialiased h-5">Paga por Whatsapp</span>}
						</button>
					</div>
					{/* Display Errors if any */}
					{requestError ? <div className="row classic-cart-total-container mt-5 text-red text-xs lg:text-lg font-helvetica-text font-normal antialiased"> {requestError} </div> : ""}
				</div>
			) : (
				<EmptyCart />
			)}
		</div>

	);
};

export default FlyCartItemsContainer;
