/* eslint-disable react/react-in-jsx-scope */
import Link from "next/link";
import { useQuery } from "@apollo/client";
import MORE_SALE_WOMEN from "../../../queries/more-sale-cart-empty-women";
import MORE_SALE_MEN from "../../../queries/more-sale-cart-empty-men";
import GET_CART from "../../../queries/get-cart";
import Image from "next/image";
import { isEmpty } from "lodash";
import clientConfig from "../../../../client-config";
import {getImageFromCloudinary} from "../../../functions";

const EmptyCart = () => {


    const { load, errorW, data: mujer } = useQuery(MORE_SALE_WOMEN, {
        onCompleted: () => {
        },
    });

    const { loading, error, data: hombre } = useQuery(MORE_SALE_MEN, {
        onCompleted: () => {
        },
    });

    let female = mujer?.productCategory?.products?.nodes[0]?.image?.guid;
    let male = hombre?.productCategory?.products?.nodes[0]?.image?.guid;

    return (
        <div className="mt-10 w-full h-screen">
            <div className="scroll-container empty-cart">
                <div className="flex-col flex content-center items-center mb-40">
                    <h2 className="place-self-start px-5 text-black text-[24px] font-helvetica-text font-normal">Tu carrito está vacío</h2>
                    <h3 className="place-self-start px-5 mb-13.5 mt-2 text-[24px] font-helvetica-text font-normal text-gray-label">Empieza por lo más vendido para mujer o para hombre.</h3>
                    <div className="w-fit place-self-start h-max px-18">
                        <div className="relative">
                            {female ? (
                                <Image
                                    src={getImageFromCloudinary(female, "", 614, 614)}
                                    alt="Más vendido mujer"
                                    className="rounded-lg"
                                    width={614}
                                    height={614}
                                    placeholder="blur"
                                    blurDataURL="data:image/jpeg;base64,/9j/UIRC#uoz?ws:VYj[o}fkRjkCjYayozayf5of"
                                    quality={100}
                                    unoptimized={true}
                                />
                            ) : !isEmpty(clientConfig.singleImagePlaceholder) ? (
                                <Image
                                    src={clientConfig.singleImagePlaceholder}
                                    alt="Más vendido mujer"
                                    className="rounded-lg"
                                    width={614}
                                    height={614}
                                    quality={100}
                                />
                            ) : null}

                            <Link href="/categoria/mujer/lo-mas-vendido/" legacyBehavior>
                                <button className="flex flex-col bg-black hover:opacity-80 bottom-4 inset-x-4 absolute pb-2 px-1.5 rounded-lg items-center justify-center">
                                    <span className="p-2 text-sm font-helvetica-text font-medium antialiased text-white flex flex-row items-center justify-center w-full h-10 pb-0 relative">Compra Mujer</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-fit place-self-start h-max mt-4 px-18">
                        <div className="relative">
                            {male ? (
                                <Image
                                    src={getImageFromCloudinary(male, "", 614, 614)}
                                    alt="Más vendido hombre"
                                    className="rounded-lg"
                                    width={614}
                                    height={614}
                                    placeholder="blur"
                                    blurDataURL="data:image/jpeg;base64,/9j/UIRC#uoz?ws:VYj[o}fkRjkCjYayozayf5of"
                                    quality={100}
                                    unoptimized={true}
                                />
                            ) : !isEmpty(clientConfig.singleImagePlaceholder) ? (
                                <Image
                                    src={clientConfig.singleImagePlaceholder}
                                    alt="Más vendido hombre"
                                    className="rounded-lg"
                                    width={614}
                                    height={614}
                                    quality={100}
                                />
                            ) : null}

                            <Link href="/categoria/hombre/lo-mas-vendido/" legacyBehavior>
                                <button className="flex flex-col bg-black hover:opacity-80 bottom-4 inset-x-4 absolute pb-2 px-1.5 rounded-lg items-center justify-center">
                                    <span className="p-2 text-sm font-helvetica-text font-medium antialiased text-white flex flex-row items-center justify-center w-full h-10 pb-0 relative">Compra Hombre</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-1"></div>
        </div>
    );
};

export default EmptyCart;