/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import DrawerCart from "./cart-page/DrawerCart";

const CartIcon = (props) => {
	const { insideCart } = props;
	const [cart] = useContext(AppContext);
	const productsCount = (null !== cart && Object.keys(cart).length) ? cart.totalProductsCount : "";
	const [menuOpenCart, setMenuOpenCart] = useState(false);

	return (
		<div>
			<a id="mycartIcon"
				href="#"
				onClick={() => { insideCart !== true ? setMenuOpenCart(true) : ""; }}
				aria-label="Carrito"
				className={`${props.isMenuVisible ? "visible" : "visible"} w-10 h-10 hover:bg-gray-lightest rounded-md cursor-pointer text-black flex items-center justify-center order-3 cursor-pointer relative mr-1`}>				
				<svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
					<circle cx="9" cy="21" r="1"></circle>
					<circle cx="20" cy="21" r="1"></circle>
					<path d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"></path>
				</svg>
				{productsCount ? <span className="cartCount absolute bg-black w-4 h-4 rounded-full text-white text-center leading-4">{productsCount}</span> : ""}
				<span className="hidden">Carrito</span>
			</a>
			<DrawerCart open={menuOpenCart} handleClose={setMenuOpenCart} />
		</div>
	);
};

export default CartIcon;
