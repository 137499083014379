/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import SvgWhatsapp from "./icons/SvgWhatsapp";

const WhatsappWidget = () => {

	const handleWhatsappClick = () => {
			
		const formatWhatsapp = "https://api.whatsapp.com/send/?phone=%2B573103662024&text=Hola&type=phone_number&app_absent=0";
		
		window.open(formatWhatsapp, "_blank");
	};

	return (
		<div className="fixed bottom-2 right-2 z-40">
			<div className="whatsapp-circle" onClick={handleWhatsappClick}>
				<SvgWhatsapp />
			</div>
		</div >
	);
};

export default WhatsappWidget;
