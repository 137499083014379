import gql from "graphql-tag";

/**
 * GraphQL DELETE_LINE_ITEMS_ORDER data.
 */
const DELETE_LINE_ITEMS_ORDER = gql`query ($orderId:Int, $protectedBy:String, $type:String) {
    deleteAllLineOrder(orderId: $orderId, protectedBy: $protectedBy, type: $type ) {
        status
  }
}
`;

export default DELETE_LINE_ITEMS_ORDER;
