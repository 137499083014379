import * as React from "react";

export default function LoadingSpinnerCss() {
    return (
        <div className="grid grid-cols-5">
            <div className="place-self-center col-start-3 col-end-4">
                {/*<CircularProgress />*/}
                <div className="flex justify-center items-center">
                    <div className="spinner-box">
                        <div className="three-quarter-spinner">                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}