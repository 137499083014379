/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import Link from "next/link";
import dynamic from "next/dynamic";
import CartIcon from "./cart/CartIcon";
import { useState, useContext, useRef, useEffect } from "react";
import { AuthContext } from "./context/auth-context";
//import {isMobile} from "react-device-detect";
const DrawerMenu = dynamic(
    () => import("./menu/DrawerMenu"),
    { ssr: false }
  );
//import Search from "./search";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useLogoutMutation } from "../hooks/useLogoutMutation";
import { useRouter } from "next/router";
import Image from "next/image";
import { getImageFromCloudinary } from "../functions";
import clientConfig from "../../client-config";

const Nav = (props) => {

    const { categories, mainMenu } = props;
    const [isMenuVisible, setMenuVisibility] = useState(false);
    const [showMe, setShowMe] = useState(0);
    const [thisCat, setThisCat] = useState(0);
    const [showSearch, setshowSearch] = useState(false);
    const [user, setUser] = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [activeImage1, setActiveImage1] = useState(clientConfig.productImagePlaceholder282);
    const [activeImage2, setActiveImage2] = useState(clientConfig.productImagePlaceholder282);
    const [activeImage3, setActiveImage3] = useState(clientConfig.productImagePlaceholder282);
    const [showScroll, setShowScroll] = useState(false);
    const anchorRef = useRef(null);
    const { logoutMutation } = useLogoutMutation();
    const router = useRouter();
    let urlPage = router?.asPath ? router?.asPath : "";

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        if (event == 1) return window.location = "/panel-usuario/resumen/";
        if (event == 2) {
            logoutMutation();
            window.location = "/";
        }
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", checkScrollTop, { passive: true });
            return function cleanup() {
                window.removeEventListener("scroll", checkScrollTop, { passive: true });
            };
        }
    });

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 50) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 50) {
            setShowScroll(false);
        }
    };

    let men = mainMenu?.cat_hombre?.nodes[0];
    let women = mainMenu?.cat_mujer?.nodes[0];
    let faceMask = mainMenu?.cat_tapabocas?.nodes[0];
    let pets = mainMenu?.cat_mascotas?.nodes[0];

    let menu = categories?.map(function (sku1) { return sku1; });
    let menuFeatured = menu[menu.length - 1];
    menu.pop();
    let womenTmp = [], menTmp = [], subMenuWomen = [], subMenuMen = [];

    if (menu.length && men && women) {

        for (var i = 0; i < women?.children?.nodes?.length; i++) {
            if (women?.children?.nodes[i]?.count != null && women?.children?.nodes[i]?.products?.nodes?.length >= 1) {
                womenTmp.push(women?.children?.nodes[i]);
            }
        }

        for (i = 0; i < men?.children?.nodes?.length; i++) {
            if (men?.children?.nodes[i]?.count != null && men?.children?.nodes[i]?.products?.nodes?.length >= 1) {
                menTmp.push(men?.children?.nodes[i]);
            }
        }

        for (i = 0; i < menu.length; i++) {
            if (menu[i]?.label == women?.name) {
                for (var j = 0; j < menu[i]?.childItems?.nodes?.length; j++) {
                    for (var k = 0; k < womenTmp.length; k++) {
                        if (menu[i]?.childItems?.nodes[j]?.label == womenTmp[k].name)
                            subMenuWomen.push(womenTmp[k]);
                    }
                }
            }
            if (menu[i]?.label == men?.name) {
                for (j = 0; j < menu[i]?.childItems?.nodes?.length; j++) {
                    for (k = 0; k < menTmp.length; k++) {
                        if (menu[i]?.childItems?.nodes[j]?.label == menTmp[k].name)
                            subMenuMen.push(menTmp[k]);
                    }
                }
            }
        }
    }

    const setPictures = (category, subCategory, index) => {//revisar aca para agregar accesorios
        switch (category) {
            case "Mujer":
                if (subCategory != "") {
                    setActiveImage1(subMenuWomen[index]?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(subMenuWomen[index]?.products?.nodes[1]?.image?.guid);
                } else {
                    setActiveImage1(women?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(women?.products?.nodes[1]?.image?.guid);
                }
                break;
            case "Hombre":
                if (subCategory != "") {
                    setActiveImage1(subMenuMen[index]?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(subMenuMen[index]?.products?.nodes[1]?.image?.guid);
                } else {
                    setActiveImage1(men?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(men?.products?.nodes[1]?.image?.guid);
                }
                break;
            case "Accesorios":
                if (subCategory == "Tapabocas") {
                    setActiveImage1(faceMask?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(faceMask?.products?.nodes[1]?.image?.guid);
                    setActiveImage3(faceMask?.products?.nodes[2]?.image?.guid);
                } else if (subCategory == "Mascotas") {
                    setActiveImage1(pets?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(pets?.products?.nodes[1]?.image?.guid);
                    setActiveImage3(pets?.products?.nodes[2]?.image?.guid);
                } else if (subCategory == "") {
                    setActiveImage1(pets?.products?.nodes[0]?.image?.guid);
                    setActiveImage2(pets?.products?.nodes[1]?.image?.guid);
                    setActiveImage3(pets?.products?.nodes[2]?.image?.guid);
                }
                break;

            default:
                break;
        }
    };

    return <>
        <nav key={"navPrincipal"} className={`${showScroll ? "border-b border-gray-light2" : "border-b border-gray-light2"} bg-white p-1 z-50`}>
            <div className="flex items-center justify-between flex-wrap lg:container mx-auto">
                {/*Menu button*/}
                <div className="block lg:hidden order-1 w-20">
                    <div onClick={() => setMenuVisibility(!isMenuVisible)} className="flex items-center px-2 py-2 text-black focus:outline-none">
                        <svg className={`${isMenuVisible ? "hidden" : "visible"} fill-current h-4 w-4`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>

                        <svg xmlns="http://www.w3.org/2000/svg" className={`${isMenuVisible ? "visible" : "hidden"} h-5 w-5`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/*MMenu in mobile*/}
                <div className="hidden lg:block overflow-hidden lg:h-full flex items-center w-70 lg:order-first order-4">
                    {menu?.length ?
                        <ul className="flex md:flex-row flex-col bg-white">
                            {menu?.map((category, index) =>
                                <li className="menulabel" key={"desktop_" + index}>
                                    {category.label !== "Hot Sale" ?
                                        <p className={`${showMe && thisCat === index ? "bg-gray-lightest" : ""} block mt-4 lg:flex lg:items-center text-sm lg:px-4 h-10 justify-center text-center rounded-md select-none lg:mt-0 font-helvetica-text font-medium antialiased text-black hover:bg-gray-lightest`}
                                            onMouseEnter={() => { setShowMe(1); setPictures(category.label, "", index); setThisCat(index); }}
                                            onMouseLeave={() => setShowMe(0)}>
                                            {category.label}
                                        </p>
                                        :
                                        <Link
                                            href={`${category.path}`}
                                            className={`${showMe && thisCat === index ? "bg-gray-lightest" : ""} block mt-4 lg:flex lg:items-center text-sm lg:px-4 h-10 justify-center text-center rounded-md cursor-pointer lg:mt-0 font-helvetica-text font-medium antialiased text-red-hot hover:bg-gray-lightest`}
                                            onMouseEnter={() => { setShowMe(1); setPictures(category.label, "", index); setThisCat(index); }}
                                            onMouseLeave={() => setShowMe(0)}
                                        >

                                            {category.label}
                                        </Link>
                                    }

                                    {
                                        category?.childItems?.nodes?.length != 0 ? (
                                            <div id="menucontainer" className="sub-menu absolute z-50 min-w-full items-center left-0 font-helvetica-text font-normal text-left justify-start justify-items-start" onMouseEnter={() => setShowMe(1)}
                                                onMouseLeave={() => setShowMe(0)}>
                                                <div className="menutop border-b border-gray-light2" onMouseEnter={() => setShowMe(1)}
                                                    onMouseLeave={() => setShowMe(0)}></div>
                                                <div className="cursor-default bg-white shadow-xl min-w-full text-left sm:py-10 flex justify-center"
                                                    onMouseEnter={() => setShowMe(1)}
                                                    onMouseLeave={() => setShowMe(0)}>
                                                    <div className="flex flex-row">
                                                        {category.label != "Accesorios" ?
                                                            <div className="flex flex-col mr-15">
                                                                <span className="leading-8 text-black text-sm font-helvetica-text font-medium antialiased -mt-2.5 mb-1">Destacados</span>
                                                                {menuFeatured.childItems.nodes.map(function (e) {
                                                                    var tmp = e.path.split("/");
                                                                    return (
                                                                        <div key={"desktop-" + e.id} className="leading-6 text-sm font-helvetica-text font-medium antialiased">
                                                                            <Link
                                                                                href={`${category.path + tmp[1]}`}
                                                                                prefetch={false}
                                                                                className={`${e.label.toLowerCase().includes("blue")
                                                                                    ? "text-blue-turqui"
                                                                                    : e.label.toLowerCase().includes("sale") ? "text-red-wine" : e.label.toLowerCase().includes("premium") ? "text-yellow-premium" : e.label.toLowerCase().includes("black") ? "text-black" : "text-gray-label"} hover:text-black`}>
                                                                                {e.label}

                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            : ""}
                                                        <div className="flex flex-col mr-15 w-70">
                                                            <div className="cat leading-8 text-black text-sm font-helvetica-text font-medium antialiased -mt-2.5">Categorías</div>
                                                            <div className={`${category.label != "Accesorios" ? "grid grid-cols-2 gap-x-18 place-content-start " : ""} mt-1`}>
                                                                {category.label == "Mujer" && subMenuWomen?.map(function (single, index) {
                                                                    if (index < 4)
                                                                        return (
                                                                            <div className="leading-6 text-gray-label text-sm font-helvetica-text font-medium antialiased hover:text-black"
                                                                                key={"women-col1-" + index}
                                                                                onMouseEnter={() => setPictures(category.label, single.name, index)}
                                                                            >
                                                                                <Link href={`${single.uri}`} prefetch={false}>{single.name}</Link>
                                                                            </div>
                                                                        );
                                                                    else return (
                                                                        <div className="leading-6 text-gray-label text-sm font-helvetica-text font-medium antialiased hover:text-black"
                                                                            key={"women-col2-" + index}
                                                                            onMouseEnter={() => setPictures(category.label, single.name, index)}
                                                                        >
                                                                            <Link href={`${single.uri}`} prefetch={false}>{single.name}</Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {category.label == "Hombre" && subMenuMen?.map(function (single, index) {
                                                                    if (index < 4)
                                                                        return (
                                                                            <div className="leading-6 text-gray-label text-sm font-helvetica-text font-medium antialiased hover:text-black"
                                                                                key={"men-col1-" + index}
                                                                                onMouseEnter={() => setPictures(category.label, single.name, index)}
                                                                            >
                                                                                <Link href={`${single.uri}`} prefetch={false}>{single.name}</Link>
                                                                            </div>
                                                                        );
                                                                    else return (
                                                                        <div className="leading-6 text-gray-label text-sm font-helvetica-text font-medium antialiased hover:text-black"
                                                                            key={"men-col2-" + index}
                                                                            onMouseEnter={() => setPictures(category.label, single.name, index)}
                                                                        >
                                                                            <Link href={`${single.uri}`} prefetch={false}>{single.name}</Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {category.label == "Accesorios" && category?.childItems?.nodes?.map(function (single, index) {
                                                                    return (
                                                                        <div className="leading-6 text-gray-label text-sm font-helvetica-text font-medium antialiased hover:text-black"
                                                                            key={"accesorio-" + index}
                                                                            onMouseEnter={() => setPictures(category.label, single.label, index)}
                                                                        >
                                                                            <Link href={`${single.path}`} prefetch={false}>{single.label}</Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <a href="#" key={"nav-image1"} className="inline-flex">
                                                                <Image
                                                                    src={activeImage1 ? getImageFromCloudinary(activeImage1, ":best", 282, 282) : clientConfig.productImagePlaceholder282}
                                                                    alt="prueba"
                                                                    className="rounded-lg"
                                                                    width={282}
                                                                    height={282}
                                                                    placeholder="blur"
                                                                    blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAArACsDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgEDAAb/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAQIR/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APZhSrO0FijKvQSitog2rPVOs9A6VejCBLRWiDahToUEhDCAaBaEH//Z"
                                                                    quality={100}
                                                                />
                                                            </a>
                                                            <a href="#" key={"nav-image2"} className="ml-5 hidden xl:inline-flex">
                                                                <Image
                                                                    src={activeImage2 ? getImageFromCloudinary(activeImage2, ":best", 282, 282) : clientConfig.productImagePlaceholder282}
                                                                    alt="prueba"
                                                                    className="rounded-lg"
                                                                    width={282}
                                                                    height={282}
                                                                    placeholder="blur"
                                                                    blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAArACsDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgEDAAb/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAQIR/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APZhSrO0FijKvQSitog2rPVOs9A6VejCBLRWiDahToUEhDCAaBaEH//Z"
                                                                    quality={100}
                                                                />
                                                            </a>
                                                            {category.label == "Accesorios" ?
                                                                <a href="#" key={"nav-image3"} className="ml-5 hidden xl:inline-flex">
                                                                    <Image
                                                                        src={activeImage3 ? getImageFromCloudinary(activeImage3, ":best", 282, 282) : clientConfig.productImagePlaceholder282}
                                                                        alt="prueba"
                                                                        className="rounded-lg"
                                                                        width={282}
                                                                        height={282}
                                                                        placeholder="blur"
                                                                        blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAArACsDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgEDAAb/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAQIR/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APZhSrO0FijKvQSitog2rPVOs9A6VejCBLRWiDahToUEhDCAaBaEH//Z"
                                                                        quality={100}
                                                                    />
                                                                </a> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${showMe ? "visible" : "hidden"} bg-black opacity-50 h-screen w-full`}></div>
                                            </div>
                                        ) : (<></>)
                                    }
                                </li>
                            )}</ul>
                        : <ul className="flex md:flex-row flex-col bg-white">
                            <li className="menulabel" key={"copy1"}>
                                <span className="block mt-4 lg:flex lg:items-center lg:mt-0 text-sm font-helvetica-text font-medium antialiased text-black hover:bg-gray-lightest h-10 pr-10">
                                    Mujer
                                </span>
                            </li>
                            <li className="menulabel" key={"copy2"}>
                                <span className="block mt-4 lg:flex lg:items-center lg:mt-0 text-sm font-helvetica-text font-medium antialiased text-black hover:bg-gray-lightest h-10 pr-10">
                                    Hombre
                                </span>
                            </li>
                            <li className="menulabel" key={"copy3"}>
                                <span className="block mt-4 lg:flex lg:items-center lg:mt-0 text-sm font-helvetica-text font-medium antialiased text-black hover:bg-gray-lightest h-10 pr-10">
                                    Accesorios
                                </span>
                            </li>
                        </ul>
                    }
                </div>
                <div className="flex items-center flex-shrink-0 order-2 z-20">
                    {urlPage == "/confirmacion-pedido/" ?
                        <a href="/" aria-label="Inicio Classic Jeans">

                            <Image
                                src={clientConfig.logo}
                                alt="Inicio Classic Jeans"
                                className="rounded-lg"
                                width={56}
                                height={56}
                            />
                        </a>
                        : <Link href="/" aria-label="Inicio Classic Jeans">

                            <Image
                                src={clientConfig.logo}
                                alt="Inicio Classic Jeans"
                                className="rounded-lg"
                                width={56}
                                height={56}
                            />

                        </Link>
                    }
                </div>
                <div className="flex order-last w-20 lg:w-70 justify-end">
                    <div className="flex">
                        <Link
                            href="/buscar"
                            prefetch={false}
                            aria-label="Buscar"
                            className={`${showSearch ? "hidden" : "visible"} flex items-center justify-center w-10 h-10 text-black hover:text-black hover:bg-gray-lightest rounded-md cursor-pointer`}>

                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle>
                                <path d="M21 21l-4.35-4.35"></path>
                            </svg>
                            <span className="hidden">Buscar</span>

                        </Link>
                    </div>
                    <div className={`${isMenuVisible ? "hidden" : "hidden lg:contents"} flex`}>
                        {user && user != "" ? (
                            <div className="text-gray hover:text-black lg:mr-1">
                                <div
                                    ref={anchorRef}
                                    aria-controls={open ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    className="flex items-center justify-center w-10 h-10 text-gray hover:text-black hover:bg-gray-lightest rounded-md cursor-pointer">
                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </div>
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                                    style={{ zIndex: "50" }}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open} id="menu-list-grow">
                                                        <MenuItem className="font-helvetica-text font-normal antialiased" onClick={() => handleClose(1)}>{user.firstName + " " + user.lastName}</MenuItem>
                                                        <MenuItem className="font-helvetica-text font-normal antialiased" onClick={() => handleClose(2)}>Cerrar Sesión</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>) :
                            ((<Link
                                href="/iniciar-sesion"
                                prefetch={false}
                                aria-label="Iniciar Sesion"
                                className="flex items-center justify-center w-10 h-10 text-gray hover:text-black hover:bg-gray-lightest rounded-md cursor-pointer">

                                <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <span className="hidden">Iniciar Sesion</span>

                            </Link>))}

                    </div>
                    {urlPage == "/confirmacion-pedido/" ?
                        <CartIcon isMenuVisible={isMenuVisible} insideCart={true} />
                        : <CartIcon isMenuVisible={isMenuVisible} insideCart={false} />
                    }
                </div>
            </div>
        </nav>
        {isMenuVisible ?
            <DrawerMenu
                open={isMenuVisible}
                dataMenu={menu}
                featured={menuFeatured}
                subMenuWomen={subMenuWomen}
                subMenuMen={subMenuMen}
                handleClose={setMenuVisibility}
            /> : <></>}
    </>;
};

export default Nav;
