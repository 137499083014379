import { gql, useMutation, useApolloClient } from "@apollo/client";

const LOGOUT = gql`
	mutation Logout {
		logout(input: {}) {
			status
		}
	}
`;

export const useLogoutMutation = () => {
    const apolloClient = useApolloClient();
    const [mutation, mutationResults] = useMutation(LOGOUT);

    const logoutMutation = async () => {
        // Remove all data from the store since we are now logged out.
        await apolloClient.clearStore();
        if (typeof window !== "undefined") {
            localStorage.removeItem("classic-user");
            localStorage.removeItem("classic-cart");
            localStorage.removeItem("classic-order");
            localStorage.removeItem("classic-checkout");
            localStorage.removeItem("classic-envio");
            localStorage.removeItem("classic-contraEntrega");
            localStorage.removeItem("classic-valueContraEntrega");
            localStorage.removeItem("classic-codigo");
            localStorage.removeItem("classic-user-fidelizado");
            localStorage.removeItem("woo-session");
            localStorage.removeItem("classic-visitor");
            localStorage.removeItem("classic-method");
            localStorage.removeItem("classic-back");
        }
        return mutation();
    };

    return { logoutMutation, results: mutationResults };
};