/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { CustomizedAccordionsCookies as Accordion } from "./cookie-accordion";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    [theme.breakpoints.down("xl")]: {
      alignItems: "flex-end", // push the dialog to bottom      
    }
  },
  paper: {
    // make the content full width
    borderRadius: 0,
    position: "relative",
    backgroundColor: "#F2F2F2",
    color: "#737373",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("xl")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%"
    }
  },
  paper2: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%"
    }
  }
}));

const Settings = (props) => {
  const classes = useStyles();
  //const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (

    <Dialog
      TransitionProps={{ role: "presentation" }}
      open={props.open}
      // onEnter={console.log("Hey.")}
      maxWidth={"sm"}
      classes={{ paper: classes.paper2 }}
    >
      <DialogTitle>Resumen de privacidad
        <IconButton style={{ position: "absolute", right: 0 }} aria-label="close" onClick={() => { props.setOpen(false); }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers><p
        className="font-helvetica-text font-normal text-sm text-justify pb-3"
      >Este sitio web utiliza cookies para mejorar su experiencia mientras navega por el sitio web. De estas, las cookies que se clasifican como necesarias se almacenan en su navegador, ya que son esenciales para el funcionamiento de las funcionalidades básicas del sitio web. También utilizamos cookies de terceros que nos ayudan a analizar y comprender cómo utiliza este sitio web. Estas cookies se almacenarán en su navegador solo con su consentimiento. También tiene la opción de optar por no recibir estas cookies. Pero la exclusión voluntaria de algunas de estas cookies puede afectar su experiencia de navegación.
      </p>

        <Accordion
          sections={[
            {
              title: "Necesario",
              content: "Las cookies necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Estas cookies garantizan funcionalidades básicas y características de seguridad del sitio web, de forma anónima.",
            },
            {
              title: "Funcional",
              content: "Las cookies funcionales ayudan a realizar ciertas funcionalidades, como compartir el contenido del sitio web en plataformas de redes sociales, recopilar comentarios y otras características de terceros.",
            },
            {
              title: "Rendimiento",
              content: "Las cookies de rendimiento se utilizan para comprender y analizar los índices de rendimiento clave del sitio web, lo que ayuda a brindar una mejor experiencia de usuario a los visitantes.",
            },
            {
              title: "Analítica",
              content: "Las cookies analíticas se utilizan para comprender cómo los visitantes interactúan con el sitio web. Estas cookies ayudan a proporcionar información sobre métricas, el número de visitantes, la tasa de rebote, la fuente de tráfico, etc.",
            },
            {
              title: "Anuncio publicitario",
              content: "Las cookies publicitarias se utilizan para proporcionar a los visitantes anuncios y campañas de marketing relevantes. Estas cookies rastrean a los visitantes en los sitios web y recopilan información para proporcionar anuncios personalizados.",
            },
            {
              title: "Otras",
              content: "Otras cookies no categorizadas son las que se están analizando y aún no se han clasificado en una categoría.",
            }

          ]}
        />


      </DialogContent>
      <DialogActions>
        <div className="justify-center w-full flex lg:mx-10">
          <button onClick={() => {
            props.setParentOpen(false);
            props.setOpen(false);
            localStorage.setItem("cookies", "{}");
            // window.location = props.urlToReload;
          }} className="focus:outline-none bg-black-semi hover:opacity-80 text-white text-sm rounded-md p-2 w-50">
            Guardar y Aceptar
          </button>
        </div>
      </DialogActions>
    </Dialog>

  );
};

const Cookies = (props) => {
  const classes = useStyles();
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <>
      <Settings open={openSettings} setOpen={setOpenSettings} setParentOpen={props.setOpen} />
      <Dialog
        open={props.open}
        // onEnter={console.log("Hey.")}
        classes={{ container: classes.root, paper: classes.paper }}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <div className="flex flex-col md:flex-row py-2 px-2 xl:px-0 container mx-auto">
          <div className="font-helvetica-text font-normal text-sm text-left p-2">
            {props.anuncio} <a href="/politica-de-privacidad/"className="underline text-blue">Políticas de Privacidad</a>.
          </div>
          <div className="flex flex-row justify-center items-center align-middle my-2 md:my-0">
            <button className="focus:outline-none hover:opacity-80 text-black text-sm font-medium p-2 mx-4 w-30" onClick={() => {
              setOpenSettings(true);
            }}>
              Configuración
            </button>
            <button onClick={() => {
              props.setOpen(false);
              localStorage.setItem("cookies", "{}");
              //window.location = props.urlToReload;
            }} className="focus:outline-none bg-black-semi hover:opacity-80 text-white text-sm rounded-md p-2 mr-2 w-30">
              Aceptar
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default Cookies;

