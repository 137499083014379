import * as React from "react";

function TrashIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                id="mainIconPathAttribute"
                stroke="currentColor"
                strokeWidth="2">
            </path>
            <line x1="10" y1="11" x2="10" y2="17" strokeWidth="1"></line>
            <line x1="14" y1="11" x2="14" y2="17" strokeWidth="1"></line>
        </svg >
    );
}

export default TrashIcon;



