import gql from "graphql-tag";

export const MORE_SALE_MEN = gql`query MORE_SALE_WOMEN {
	productCategory(id: "hombre", idType: SLUG) {
	  id
	  products(first: 1, where: {status: "publish", stockStatus: IN_STOCK, orderby: {field: TOTAL_SALES, order: DESC}}) {
			nodes {
				id			
				image {
					id
					guid
				}
			}
	  	}
	}	
 }
  `;

export default MORE_SALE_MEN;